import Nav from './admin/common/menu';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './admin/common/header';

const AdminLayout = () => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="admin-layout">
        {pathname !== '/login' ? (
          <>
            <Header />
            <Nav />
          </>
        ) : null}

        <section>
          <Outlet />
        </section>
      </div>
    </>
  );
};

export default AdminLayout;
