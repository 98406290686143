import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import MDEditor from '@uiw/react-md-editor';
import apiAdmin from "@/services/api/apis/api-admin";
import Modal from "@/components/common/modal-component";

interface ContentTask {
  backend?: string;
  description1?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  detail?: string;
  end_month?: string;
  frontend?: string;
  project_code?: number;
  project_name?: string;
  start_month?: string;
}

const TaskDetail = () => {
  const {id} = useParams();
  const [taskDetail, setTaskDetail] = useState<ContentTask>();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [titleModal, setTitleModal] = useState('')
  const [contentModal, setContentModal] = useState('')
  const [markdown, setMarkdown]: [string, any] = useState('');

  useEffect(() => {
    console.log(id);
    apiAdmin.getTaskDetail(Number(id))
      .then((res) => {
        setTaskDetail(res.data);

        if (res.data.detail) {
          setMarkdown(res.data.detail);
        }
      });
  }, [id, taskDetail?.detail]);

  const changeInputText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    if (name && value) {
      setTaskDetail({
        ...taskDetail,
        [name]: value,
      });
    }
  }

  const setDetailContent = () => {
    const requestData = {
      ...taskDetail,
      detail: markdown,
    }

    apiAdmin.postTaskDetail(requestData)
      .then((res: { status: number; }) => {
        console.log(res);

        if (res.status === 200) {
          setIsVisibleModal(true);
          setTitleModal('성공');
          setContentModal('수정 및 등록 완료');
        }
      });
  }

  return (
    <div className="wrap-admin">
      {
        taskDetail &&
          <>
            <ul className="project-detail">
              <li>프로젝트명</li>
              <li>
                <input
                    type="text"
                    name="project_name"
                    value={taskDetail.project_name ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>기간</li>
              <li>
                <input
                    type="text"
                    name="start_month"
                    className="date"
                    value={taskDetail.start_month ?? ''}
                    onChange={(e) => changeInputText(e)}/>
                &nbsp;~&nbsp;
                <input
                    type="text"
                    name="end_month"
                    className="date"
                    value={taskDetail.end_month ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>사용 기술</li>
              <li>
                <span>FE:</span>
                <input
                    type="text"
                    name="frontend"
                    className="skill"
                    value={taskDetail.frontend ?? ''}
                    onChange={(e) => changeInputText(e)}/><br/>
                <span>BE:</span>
                <input
                    type="text"
                    name="backend"
                    className="skill"
                    value={taskDetail.backend ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>설명1</li>
              <li>
                <input
                    type="text"
                    name="description1"
                    value={taskDetail.description1 ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>설명2</li>
              <li>
                <input
                    type="text"
                    name="description2"
                    value={taskDetail.description2 ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>설명3</li>
              <li>
                <input
                    type="text"
                    name="description3"
                    value={taskDetail.description3 ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            <ul className="project-detail">
              <li>설명4</li>
              <li>
                <input
                    type="text"
                    name="description4"
                    value={taskDetail.description4 ?? ''}
                    onChange={(e) => changeInputText(e)}/>
              </li>
            </ul>

            {/*<ul className="project-detail">*/}
            {/*  <li>참여율</li>*/}
            {/*  <li>*/}
            {/*    /!*<textarea*!/*/}
            {/*    /!*    value=""*!/*/}
            {/*    /!*    name="participation"*!/*/}
            {/*    /!*    onChange={(e) => changeInputText(e)}/>*!/*/}
            {/*  </li>*/}
            {/*</ul>*/}

            <ul className="project-detail">
              <li>상세 설명</li>
              <li>
                <MDEditor
                  data-color-mode="dark"
                  height="700px"
                  value={markdown}
                  onChange={setMarkdown}
                />
              </li>
            </ul>

            <div className="bottom-button center">
              <button
                  className="wide"
                  type="button"
                  onClick={ () => setDetailContent() }>
                저장 및 수정
              </button>
            </div>
          </>
      }

      {
        isVisibleModal &&
        <Modal
            title={titleModal}
            text={contentModal}
            confirmText="확인"
            confirm={ () => setIsVisibleModal(false) } />
      }
    </div>
  );
};

export default TaskDetail;
