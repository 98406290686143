import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import ApiAdmin from '@/services/api/apis/api-admin';
import time from '@/utils/helpers/time';
import ButtonComponent from '@/components/common/button-component';

const BoardList = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());

  const limit: number = 8;
  const [contentList, setContentList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  useEffect(() => {
    ApiAdmin.getBoardList({
      tag: query.tag,
      page: page,
      size: limit,
    }).then((res) => {
      setTotalCount(res.data.totalCount);
      setContentList(res.data?.listItems);
      setNumPages(Math.ceil(res.data.totalCount / limit));
    });
  }, [query.tag, page, totalCount]);

  useEffect(() => {
    setPage(1);
  }, [query.tag]);

  const moveWritePage = (tag: string) => {
    navigate(`/manage-board/write?tag=${tag}`);
  };

  return (
    <div className="wrap-admin">
      <div className="content-table">
        <div className="content-list">
          <div className="item-rows header">
            <div className="title">제목</div>
            <div className="date">작성일</div>
          </div>
        </div>

        <div className="content-list">
          {contentList.length > 0 ? (
            contentList.map((item: any, index: number) => {
              return (
                <div className="item-rows" key={index}>
                  <div className="title">
                    <Link to={`/manage-board/detail/${item.idx}?tag=${query.tag}`}>
                      {item.title}
                    </Link>
                    {/*<span>{ item.type }</span>*/}
                  </div>
                  <div className="date">{time.convertDateFormat('YYYY.MM.DD', item.regdate)}</div>
                </div>
              );
            })
          ) : (
            <div className="no-data">
              {String(query.tag).toUpperCase()} 태그로 작성한 게시물 없음
            </div>
          )}
        </div>

        <div className="pagination">
          {Array(numPages)
            .fill(numPages)
            .map((_, i) => {
              return (
                <span
                  className={`${page === i + 1 ? 'active' : null}`}
                  onClick={() => setPage(i + 1)}
                  key={i}
                >
                  {i + 1}
                </span>
              );
            })}
        </div>
      </div>

      <div className="bottom-button">
        {/*<ButtonComponent*/}
        {/*  click={confirm}*/}
        {/*  name="테스트" />*/}

        <div className="right-button">
          {/*<Link to={`/admin/manage-board/write?page=${ query.page }`}>*/}
          {/*  <button type="button">새 글 등록</button>*/}
          {/*</Link>*/}

          <ButtonComponent click={() => moveWritePage(String(query.tag))} name="새 글 등록" />
        </div>
      </div>
    </div>
  );
};

export default BoardList;
