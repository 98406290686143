import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import apiAdmin from '@/services/api/apis/api-admin';
import { CompanyList } from '@/interfaces/career-interfaces';

const Companies = () => {
  const [companyList, setCompanyList] = useState<CompanyList[]>([]);
  const [lastSeq, setLastSeq] = useState<number>(0);
  const newCompanyRow = {
    idx: null,
    name: '',
    department: '',
    startDate: null,
    endDate: null,
    period: '',
  };

  useEffect(() => {
    apiAdmin.getCompanyList().then((res) => {
      setCompanyList(res.data.companies);

      const maxObjArr = res.data.companies.reduce(
        (prev: { idx: number }, value: { idx: number }) => {
          return prev.idx >= value.idx ? prev : value;
        },
      );

      setLastSeq(maxObjArr);
    });
  }, []);

  const addCompany = () => {
    setCompanyList(companyList.concat(newCompanyRow));
    setLastSeq(Number(lastSeq) + 1);
  };

  // const deleteRow = (target: number) => {
  //   setCompanyList(companyList.filter((_: CompanyList, index: number) => index !== target));
  //   setLastSeq(Number(lastSeq) + 1);
  // }

  const changeCompanyData = (companyIndex: number, target: string, value: any) => {
    const findIndex = companyList.findIndex(
      (_company: CompanyList, index) => index === companyIndex,
    );
    const propertyName = target;
    const copiedItems = [...companyList];

    copiedItems[findIndex][propertyName] = value;

    setCompanyList(copiedItems);
  };

  const calculatePeriod = (startDate: Date | null, endDate?: Date | null): string => {
    if (!endDate) {
      endDate = new Date();
    }

    let returnDate: string = '';
    const workingPeriod = dayjs(endDate).diff(startDate, 'day');

    const workingYear = Math.floor(workingPeriod / 365);
    const workingMonth = Math.round((workingPeriod % 365) / 30);

    if (workingYear > 0) {
      returnDate += `${workingYear}년`;
    }

    if (workingMonth > 0) {
      returnDate += ` ${workingMonth}개월`;
    }

    return `${returnDate}`;
  };

  const getTotalCareer = () => {
    let career = 0;
    let returnPeriod = '';
    companyList.forEach((item) => {
      career += dayjs(item.endDate ?? new Date()).diff(item.startDate, 'day');
    });

    const workingYear = Math.floor(career / 365);
    const workingMonth = Math.round((career % 365) / 30);

    if (workingYear > 0) {
      returnPeriod += `${workingYear}년`;
    }

    if (workingMonth > 0) {
      returnPeriod += ` ${workingMonth}개월`;
    }

    return returnPeriod;
  };

  const saveCompany = () => {
    apiAdmin.postSaveCompany(companyList).then((res) => {
      console.log('res', res);
    });
  };

  return (
    <div className="wrap-admin">
      <div className="total-career">총 경력: {getTotalCareer()}</div>
      <table className="company-list">
        <colgroup>
          <col className="name" />
          <col className="department" />
          <col className="start-date" />
          <col className="end-date" />
          <col className="period" />
          <col className="button" />
        </colgroup>
        <thead>
          <tr>
            <th>회사명</th>
            <th>소속 부서</th>
            <th>입사일</th>
            <th>퇴사일</th>
            <th>근무기간</th>
            {/*<th>버튼</th>*/}
          </tr>
        </thead>
        <tbody>
          {companyList.map((company, index) => {
            return (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={company.name}
                    onChange={(e) => changeCompanyData(index, 'name', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={company.department}
                    onChange={(e) => changeCompanyData(index, 'department', e.target.value)}
                  />
                </td>
                {/*<td><input type="text" value={ company.startDate } onChange={ (e) => changeCompanyData(company.idx, 'startDate', e) } /></td>*/}
                <td className="date">
                  <DatePicker
                    selected={company.startDate ? new Date(company.startDate as Date) : null}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date | null) => changeCompanyData(index, 'startDate', date)}
                  />
                </td>
                <td className="date">
                  <DatePicker
                    selected={company.endDate ? new Date(company.endDate as Date) : null}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date: Date | null) => changeCompanyData(index, 'endDate', date)}
                  />
                </td>
                <td className="working-date">
                  {calculatePeriod(company.startDate, company.endDate)}
                </td>
                {/*<td>*/}
                {/*  <button*/}
                {/*      id={ `button${ company.idx }` }*/}
                {/*      className="small red"*/}
                {/*      type="button"*/}
                {/*      onClick={ () => deleteRow(index) }>*/}
                {/*    삭제*/}
                {/*  </button>*/}
                {/*</td>*/}
              </tr>
            );
          })}

          <tr>
            <td className="add-company" colSpan={5}>
              <button
                className="small"
                type="button"
                onClick={addCompany}
              >
                회사 추가
              </button>
              <span className="blank"></span>
              <button
                className="small blue"
                type="button"
                onClick={saveCompany}
              >
                저장
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Companies;
