const notFound = () => {
  return (
    <div className="not_found_div">
      {/*<div className="icon"><FontAwesomeIcon icon={faFaceMonocle} /></div>*/}
      <div className="icon"><i className="fa-duotone fa-face-monocle" /></div>
        <div className="content">
        <div className="str">404</div>
        <div className="sub_str">ERROR</div>
        <div className="info">PAGE NOT FOUND</div>
      </div>
    </div>
  );
};

export default notFound;
