import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import apiAdmin from '@/services/api/apis/api-admin';
import kakaoLoginBtn from '@/assets/images/kakao_login_large_narrow.png';

const Login = () => {
  const restApiKey = import.meta.env.VITE_KAKAO_RESTAPI; //REST API KEY
  const redirectUri = import.meta.env.VITE_KAKAO_REDIRECT_URL; //Redirect URI

  // oauth 요청 URL
  const kakaoAuthURL = `
    https://kauth.kakao.com/oauth/authorize?client_id=${restApiKey}&redirect_uri=${redirectUri}&response_type=code
  `;

  const loginWithKakao = () => {
    window.location.href = kakaoAuthURL;
  };

  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const kakaoCode = queryParameters.get('code');

    if (kakaoCode) {
      const requestTokenHeader = {
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      };

      // 토큰 생성 시 요청 파라미터
      const data = {
        grant_type: 'authorization_code',
        client_id: import.meta.env.VITE_APP_KAKAO_CLIENT_ID,
        redirect_uri: redirectUri,
        client_secret: import.meta.env.VITE_APP_KAKAO_CLIENT_SECRET,
        code: kakaoCode,
      };

      axios
        .post(
          'https://kauth.kakao.com/oauth/token',
          data,
          {
            headers: requestTokenHeader
          }
        )
        .then((tokenRes: AxiosResponse) => {
          // 사용자 정보 요청 시 요청 헤더
          const requestInfoHeader = {
            Authorization: `Bearer ${tokenRes.data.access_token}`,
          };

          // 사용자 정보 조회 요청
          axios
            .post(
              'https://kapi.kakao.com/v2/user/me',
              null,
              {
                headers: requestInfoHeader
              }
            )
            .then((infoRes: AxiosResponse) => {
              if (infoRes.status === 200 && infoRes.data.id) {
                apiAdmin.getAuth(infoRes.data.id).then(() => {
                  sessionStorage.setItem('kakaoRefreshToken', tokenRes.data.refresh_token);
                  sessionStorage.setItem('kakaoAccessToken', tokenRes.data.access_token);

                  navigate('/');
                });
              }
            });
        });
    }
  }, [navigate, redirectUri]);

  return (
    <div className="login">
      <div className="title">ADMIN</div>
      <div className="button">
        <img
          src={kakaoLoginBtn}
          style={{ width: '222px' }}
          alt="카카오 로그인 버튼"
          onClick={loginWithKakao}
        />
      </div>
    </div>
  );
};

export default Login;
