import React, { useEffect, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import ApiBoard from '@/services/api/apis/api-board';
import ApiAdmin from '@/services/api/apis/api-admin';
// import apiAdmin from '@/services/api/apis/api-admin';

const WritePost = () => {
  // const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());
  // const [ options, setOptions ] = useState<string[]>([]);
  // const [ category, setCategory ] = useState('');
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState('');
  const [markdown, setMarkdown]: [string, any] = useState('');

  useEffect(() => {
    ApiBoard.getTags()
      .then(() => {
        // const currentCategory = _.filter(res.data.categories, (categories) => categories.category === String(query.page).toUpperCase())
        // const subCategory = _.filter(currentCategory[0].items, (item) => item.subject !== null);
        // setOptions(subCategory);
      });
  }, [query.page]);

  // const selectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setCategory(e.target.value);
  // };

  const inputSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const inputTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTags(e.target.value);
  };

  const saveNewPost = () => {
    const registerData = {
      title,
      tags,
      contentMarkdown: markdown,
      page: String(query.page).toUpperCase(),
      type: 'markdown',
    };

    ApiAdmin.postRegisterNewPost(registerData)
      .then(() => {
        // if (res.data.result === 'success') {
        //   navigate(`/admin/manage-board/list?page=${ query.page }`);
        // }
      });
  };

  return (
    <div className="wrap-admin">
      <div className="input-title">
        {/*<select*/}
        {/*    value={ category }*/}
        {/*    onChange={ selectCategory }>*/}
        {/*  <option value="">태그 선택</option>*/}

        {/*  {*/}
        {/*    options.map((option: any, index: number) => {*/}
        {/*      return(*/}
        {/*        <option*/}
        {/*            key={ option.subject }*/}
        {/*            value={ option.idx }>*/}
        {/*          { option.subject }*/}
        {/*        </option>*/}
        {/*      )*/}
        {/*    })*/}
        {/*  }*/}
        {/*</select>*/}
        <input type="text" placeholder="제목 입력" value={title} onChange={inputSubject} />

        <input type="text" placeholder="태그 입력(쉼표 구분)" value={tags} onChange={inputTags} />
      </div>

      <MDEditor
        data-color-mode="dark"
        height="700px"
        value={markdown}
        onChange={setMarkdown}
      />

      <div className="bottom-button">
        <button className="wide" type="button" onClick={saveNewPost}>
          글 등록하기
        </button>
      </div>
    </div>
  );
};

export default WritePost;
