const AdminMain = () => {
  return (
    <div className="wrap-admin">
      <div className="main-info">
        <div className="count">
          <div className="count-row">
            <div>등록 게시글</div>
            <div>?</div>
          </div>
          <div className="count-row">
            <div>관리 카테고리</div>
            <div>?</div>
          </div>
          <div className="count-row">
            <div>등록 포트폴리오</div>
            <div>?</div>
          </div>
        </div>
        <div className="recent-status">차트 영역</div>
      </div>
      <div className="main-info">
        <div className="to-do">
          <div className="title">To Do</div>
        </div>
      </div>
    </div>
  );
};

export default AdminMain;
