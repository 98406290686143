import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import apiAdmin from '@/services/api/apis/api-admin';
import MDEditor from '@uiw/react-md-editor';
import queryString from 'query-string';
// import apiBoard from '../../../services/api/apis/api-board';
// import _ from 'lodash-es';

const DetailPost = () => {
  /** 세그먼트로 넘어온 파라미터 */
  const { idx } = useParams();

  /** 쿼리스트링으로 넘어온 파라미터 */
  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());

  const navigate = useNavigate();

  /** 태그 셀렉트 박스의 옵션 */
  // const [ options, setOptions ] = useState<string[]>([]);

  /** api 응답을 통해 받은 글 데이터 */
  const [content, setContent]: any = useState([]);

  /** 태그 셀렉트 박스의 선택 중인 값 */
  // const [ category, setCategory ] = useState('');

  /** 글의 제목 */
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState('');
  const [markdown, setMarkdown]: [string, any] = useState('');

  useEffect(() => {
    apiAdmin
      .getBoardDetail({
        idx: idx,
      })
      .then((res: any) => {
        const content = res.data.detail[0];
        setContent(content);
        setTags(content.tags);
        setTitle(content.title);

        // html 데이터
        // if (content.content_html) editorRef.current?.getInstance().setHTML(content.content_html);

        // 마크다운 데이터
        if (content.content_markdown)
          // editorRef.current?.getInstance().setMarkdown(content.content_markdown);

          setMarkdown(content.content_markdown);
      });

    // 태그 셀렉트 박스의 옵션 값 조회
    // apiBoard.getTags()
    //   .then((res) => {
    //     const currentCategory = _.filter(res.data.categories, (categories) => categories.category === String(query.page).toUpperCase());
    //     // const subCategory = _.filter(currentCategory[0].items, (item) => item.subject !== null);
    //
    //     // setOptions(subCategory);
    //   });
  }, [idx, content.content_html, content.content_markdown, query.page]);

  /**
   * 셀렉트 박스의 값을 선택하면 처리한다.
   * @param e 셀렉트 박스 엘리먼트의 이벤트 데이터
   */
  // const selectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setCategory(e.target.value);
  // };

  /**
   * 제목을 입력하면 처리한다.
   * @param e 제목 입력 박스 엘리먼트의 이벤트 데이터
   */
  const inputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const inputTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTags(e.target.value);
  };

  // const inputMarkdown = () => {
  //   console.log(markdown);
  // }

  const modifyPost = () => {
    console.log(markdown);
    const registerData = {
      title,
      tags,
      contentMarkdown: markdown,
      idx,
      page: String(query.page).toUpperCase(),
      type: 'markdown',
    };

    apiAdmin.putModifyPost(registerData).then((res) => {
      if (res.data?.result === 'success') {
        navigate(`/manage-board/list?tag=${query.tag}`);
      }
    });
  };

  return (
    <div className="wrap-admin">
      <div className="input-title">
        {/*<select*/}
        {/*    key={ category }*/}
        {/*    value={ category }*/}
        {/*    onChange={ selectCategory }>*/}
        {/*  <option value="">태그 선택</option>*/}

        {/*  {*/}
        {/*    options.map((option: any, index: number) => {*/}
        {/*      return(*/}
        {/*        <option*/}
        {/*          key={ option.subject }*/}
        {/*          value={ option.idx }>*/}
        {/*          { option.subject }*/}
        {/*        </option>*/}
        {/*      )*/}
        {/*    })*/}
        {/*  }*/}
        {/*</select>*/}
        <input type="text" placeholder="제목 입력" value={title} onChange={inputTitle} />

        <input type="text" placeholder="태그 입력(쉼표 구분)" value={tags} onChange={inputTags} />
      </div>

      <MDEditor
        data-color-mode="dark"
        height="700px"
        value={markdown}
        onChange={setMarkdown}
      />

      <div className="bottom-button">
        <button className="wide" type="button" onClick={modifyPost}>
          글 수정하기
        </button>
      </div>
    </div>
  );
};

export default DetailPost;
