import axios from '../index';

const ApiAdmin = {
  /** 카카오로 인증 받은 정보로 로그인 정보를 조회한다. */
  getAuth: (authNumber: number) => axios.requestGet(`/admin/auth/${authNumber}`),

  /** 메인 화면의 post 목록을 조회한다. */
  getTagsInBoard: () => axios.requestGet('/admin/tags'),

  /** 네비게이션 메뉴의 메뉴 목록을 조회한온다. */
  getBoardList: (data: object) => axios.requestGet('/admin/board-list', data),

  /** 선택한 게시글의 상세 페이지 데이터를 조회한다. */
  getBoardDetail: (data: object) => axios.requestGet('/admin/board-detail', data),

  /** 경력 관리의 회사 목록을 조회한다. */
  getCompanyList: () => axios.requestGet('/admin/company-list'),

  /** 경력 관리의 회사 목록에 회사를 등록한다. */
  postSaveCompany: (data: object) => axios.requestPost('/admin/save-company', data),

  /** 글을 수정한다. */
  putModifyPost: (data: object) => axios.requestPut('/admin/modify-post', data),

  /** 글을 등록한다. */
  postRegisterNewPost: (data: object) => axios.requestPost('/admin/register-new-post', data),

  /** 소개글을 조회한다. */
  getIntroduce: () => axios.requestGet('/admin/introduce'),

  /** 소개글을 저장한다. */
  postRegisterIntroduce: (data: { contentHtml: string; contentMarkdown: string; }) =>
    axios.requestPost('/admin/register-new-introduce', data),

  getTaskByCompany: (companyIdx: number) => axios.requestGet('/admin/get-company-task', { companyIdx }),

  getTaskDetail: (taskIdx: number) => axios.requestGet('/admin/get-task-detail', { taskIdx }),

  postTaskDetail: (data: object) => axios.requestPost('/admin/post-task-detail', data),

  /** 이미지를 업로드한다. */
  postUploadImage: (data: object) =>
    axios.requestPost('/admin/upload-image', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
};

export default ApiAdmin;
