import { useEffect, useState } from 'react';
import axiosMain from '@/services/api/apis/api-board';
import _ from 'lodash-es';
import SubCategoryList from '@/components/admin/sub-category-list';

const MenuList = () => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axiosMain.getTags().then((res) => {
      setCategoryList(res.data.categories);
    });
  }, []);

  const addMenu = async (categoryList: any, index: number) => {
    const lastOrder = _.reduce(categoryList[index].items, (a, b) =>
      a.category_order >= b.category_order ? a : b,
    );
    const addItem = {
      idx: null,
      board_name_en: lastOrder.board_name_en,
      subject: '',
      parent_category: lastOrder.parent_category,
      category_order: lastOrder.category_order + 1,
    };

    // setCategoryList([...categoryList[index].items, subCategory ]);

    // categoryList[index].items = [
    //   ...categoryList[index].items,
    //   addItem,
    // ]
    //
    // categoryList = [
    //   ...categoryList,
    // ]

    categoryList = [
      ...categoryList.slice(0, index),
      {
        ...categoryList[index],
        items: [...categoryList[index].items, addItem],
      },
      ...categoryList.slice(index + 1),
    ];

    setCategoryList(categoryList);
  };

  const setCategoryName = (parent: string, order: number, inputValue: string) => {
    let editList: any = categoryList;
    _.flatMap(editList, (category: any) =>
      _.flatMap(_.get(category, 'items'), (item) => {
        if (item.board_name_en === parent && item.category_order === order) {
          item.subject = inputValue;
        }
      }),
    );

    editList = [...editList];

    setCategoryList(editList);
  };

  const saveCategory = () => {
    console.log('saveCategory', categoryList);

    axiosMain.postAddCategory(categoryList).then((res) => {
      console.log('res', res);
    });
  };

  return (
    <div className="wrap-admin">
      <div className={'category-list'}>
        {_.map(categoryList, (listData: any, index: number) => {
          return (
            <div className={'main-category'} key={index}>
              <div className="main">
                {listData.category}
                <div className={'add-button'} onClick={() => addMenu(categoryList, index)}>
                  +
                </div>
              </div>
              <div className="sub">
                <SubCategoryList data={listData.items} setCategoryName={setCategoryName} />
              </div>
            </div>
          );
        })}
        <div className="save-category">
          <button onClick={saveCategory}>저장</button>
        </div>
      </div>
    </div>
  );
};

export default MenuList;
