import {AxiosResponse} from 'axios';
import configAxios from './config-axios';

/**
 * get 요청
 * @param url api url
 * @param data api 요청 데이터
 * @param header api 요청 헤더
 * @returns api 응답 데이터 or 오류 정보
 */
const requestGet = async (url: string, data?: any, header?: any): Promise<any> => {
  try {
    let response: AxiosResponse;

    if (import.meta.env.MODE === 'development') {
      console.log(
        `%ccall [get] ${url}`,
        'color: black; background-color: #a4f644; padding: 2px 5px;',
      );
    }

    if (data) {
      response = await configAxios.get(`${url}`, {
        params: data,
        headers: header,
      });
    } else {
      response = await configAxios.get(`${url}`);
    }

    return response;
  } catch (error) {
    return error;
  }
};

/**
 * post 요청
 * @param url api url
 * @param data api 요청 데이터
 * @param header api 요청 헤더
 * @returns api 응답 데이터 or 오류 정보
 */
const requestPost = async (url: string, data?: any, header?: any): Promise<any> => {
  if (import.meta.env.MODE === 'development') {
    console.log(`%ccall [post] ${url}`, 'color: black; background-color: #a4f644; padding: 2px 5px;');
  }

  try {
    return await configAxios.post(`${url}`, data, header);
  } catch (error) {
    return error;
  }
};

/**
 * put 요청
 * @param url api url
 * @param data api 요청 데이터
 * @param header axios 요청 헤더
 * @returns api 응답 데이터 or 오류 정보
 */
const requestPut = async (url: string, data?: any, header?: object): Promise<any> => {
  if (import.meta.env.MODE === 'development') {
    console.log(`%ccall [put] ${url}`, 'color: black; background-color: #a4f644; padding: 2px 5px;');
  }

  try {
    return await configAxios.put(`${url}`, data, header);
  } catch (error: any) {
    return error.response;
  }
};

/**
 * delete 요청
 * @param url api url
 * @returns api 응답 데이터 or 오류 정보
 */
const requestDelete = async (url: string): Promise<any> => {
  if (import.meta.env.MODE === 'development') {
    console.log(
      `%ccall [delete] ${url}`,
      'color: black; background-color: #a4f644; padding: 2px 5px;',
    );
  }

  try {
    return await configAxios.delete(`${url}`);
  } catch (error) {
    return error;
  }
};

const exportRequests = {
  requestGet,
  requestPost,
  requestPut,
  requestDelete,
};

export default exportRequests;
