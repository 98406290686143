import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import apiAdmin from "@/services/api/apis/api-admin";

interface CompanyList {
  department: string;
  endDate: string;
  idx: number;
  name: string;
  startDate: string;
}

interface TaskList {
  end_month: string;
  project_code: number;
  project_name: string;
  start_month: string;
}

const CompanyAndTasks = () => {
  const [companyList, setCompanyList] = useState<CompanyList[] | null>(null);
  const [taskList, setTaskList] = useState<TaskList[]>();
  const selectedCompany = useRef<number | null>(null);

  const getTaskList = (companyIdx: number) => {
    selectedCompany.current = companyIdx;

    apiAdmin.getTaskByCompany(companyIdx)
      .then((res) => {
        console.log(companyIdx);
        setTaskList(res.data.data);
      });
  };

  useEffect(() => {
    apiAdmin.getCompanyList()
      .then((res: { data: { companies: CompanyList[] } }) => {
        setCompanyList(res.data.companies);
      });
  }, []);

  return (
    <div className="wrap-admin">
      <div className="companies">
        {
          companyList &&
          companyList.map((companyData) => {
            return (
              <div
                  className={ companyData.idx === selectedCompany.current ? 'company active' : 'company' }
                  key={companyData.idx}
                  onClick={ () => getTaskList(companyData.idx) }>
                { companyData.name }
              </div>
            )
          })
        }
      </div>

      <div className="tasks">
        {
          taskList &&
          taskList.map((task) => {
            return (
              <Link to={`/manage-career/task-detail/${task.project_code}`} key={ task.project_code }>
                <div className="task">
                  { task.project_name } <span>({task.start_month } ~ { task.end_month ?? '진행 중' })</span>
                </div>
              </Link>
            );
          })
        }
      </div>
    </div>
  );
};

export default CompanyAndTasks;
