import { useEffect, useState } from 'react';
import _ from 'lodash-es';

const SubCategoryList = (props: any) => {
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const setCategoryName = (mainCategoryName: string, categoryOrder: number, inputValue: string) => {
    props.setCategoryName(mainCategoryName, categoryOrder, inputValue);
  };

  useEffect(() => {
    setCategoryList(props.data);
  }, [props.data]);

  return (
    <>
      {_.map(categoryList, (item) => {
        return (
          item.category_order && (
            <div className="input-area" key={item.category_order}>
              <input
                type="text"
                value={item.subject}
                maxLength={20}
                onChange={(e) =>
                  setCategoryName(item.board_name_en, item.category_order, e.target.value)
                }
              />
              {/*<button>저장</button>*/}
            </div>
          )
        );
      })}
    </>
  );
};

export default SubCategoryList;
