import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

const Header = () => {
  const navigate = useNavigate();

  if (!sessionStorage.getItem('kakaoAccessToken')) {
    navigate('/login');
  }

  const location = useLocation();
  const [boardName, setBoardName] = useState('ddonggra.me');

  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());

  const logout = () => {
    axios
      .post(
        'https://kapi.kakao.com/v1/user/logout',
        null,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${sessionStorage.getItem('kakaoAccessToken')}`,
          }
        }
      ).then(() => {
        sessionStorage.removeItem('kakaoRefreshToken');
        sessionStorage.removeItem('kakaoAccessToken');
        window.location.href = '/'
      }).catch((e) => {
        // 이미 만료된 토큰일 경우
        if (e.response.data.code === -401) {
          window.location.href = '/'
        }
      });
  }

  useEffect(() => {
    if (location.pathname === '/') {
      setBoardName('메인');

    } else if (location.pathname.includes('manage-category')) {
      setBoardName('메뉴 관리');

    } else if (location.pathname.includes('manage-board')) {
      setBoardName(`게시물 관리 - ${String(query.tag).toUpperCase()}`);

    } else if (location.pathname.includes('companies')) {
      setBoardName('경력 관리 - 회사 목록 관리');

    } else if (location.pathname.includes('manage-project')) {
      setBoardName('경력 관리 - 프로젝트 관리');

    } else if (location.pathname.includes('introduce')) {
      setBoardName('경력 관리 - 소개 관리');

    } else if (location.pathname.includes('company-and-tasks')) {
      setBoardName('경력 관리 - 업무 관리');
    }
  }, [location.pathname, query.tag]);

  return (
    <header className="admin-header">
      <div className="board-name">{boardName}</div>
      <div className="right-area" onClick={logout}>나가기</div>
    </header>
  );
};

export default Header;
