import React, {useEffect, useRef, useState} from 'react';
import MDEditor from '@uiw/react-md-editor';
import apiAdmin from "@/services/api/apis/api-admin";

const Introduce = () => {
  /** 토스트 에디터 창으로 사용하는 요소 */
  const editorRef: React.MutableRefObject<any> = useRef();
  const [markdown, setMarkdown]: [string, any] = useState('');

  useEffect(() => {
    apiAdmin.getIntroduce().then((res) => {
      console.log(res);

      if (res.data.data[0].content_html) editorRef.current?.getInstance().setHTML(res.data.data[0].content_html);

      // 마크다운 데이터
      if (res.data.data[0].content_markdown)
        setMarkdown(res.data.data[0].content_markdown);
    });
  }, []);

  const applyIntroduce = () => {
    const registerData = {
      contentHtml: editorRef.current.getInstance().getHTML(),
      contentMarkdown: editorRef.current.getInstance().getMarkdown(),
    };

    apiAdmin.postRegisterIntroduce(registerData).then((res) => {
      console.log(res);
    });
  };

  return (
    <div className="wrap-admin">
      <MDEditor
        data-color-mode="dark"
        height="700px"
        value={markdown}
        onChange={setMarkdown}
      />

      <div className="bottom-button">
        <button className="wide" type="button" onClick={ applyIntroduce }>
          등록 및 수정하기
        </button>
      </div>
    </div>
  );
};

export default Introduce;
