import { createBrowserRouter } from 'react-router-dom';
// import { Suspense } from 'react';
// import {
//   Login,
//   NotFound,
//   AdminLayout,
//   AdminMain,
//   CategoryList,
//   BoardList,
//   WritePost,
//   DetailPost,
//   Companies,
//   ProjectList,
//   Introduce,
//   CompanyAndTasks,
//   TaskDetail
// } from './components';
import Login from '@/pages/login/login.tsx';
import NotFound from '@components/not-found.tsx';
import AdminLayout from '@components/admin-layout.tsx';
import AdminMain from '@/pages/main/admin-main.tsx';
import CategoryList from '@/pages/manage-menu/menu-list.tsx';
import BoardList from '@/pages/manage-board/board-list.tsx';
import WritePost from '@/pages/manage-board/write-post.tsx';
import DetailPost from '@/pages/manage-board/detail-post.tsx';
import Companies from '@/pages/manage-portfolio/companies.tsx';
import ProjectList from '@/pages/manage-portfolio/project-list.tsx';
import Introduce from '@/pages/manage-portfolio/introduce.tsx';
import CompanyAndTasks from '@/pages/manage-portfolio/company-and-tasks.tsx';
import TaskDetail from '@/pages/manage-portfolio/task-detail.tsx';

const routerGroup = createBrowserRouter([
  {
    path: '/login',
    element: (
      <Login />

    ),
  },
  {
    path: '/*',
    element: (
      <NotFound />

    ),
  },
  {
    path: '/',
    element: (
      <AdminLayout />

    ),
    children: [
      {
        path: '',
        element: (
          <AdminMain />

        ),
      },
      {
        path: 'manage-category',
        element: (
          <CategoryList />

        ),
      },
      {
        path: 'manage-board',
        children: [
          {
            path: 'list',
            element: (
              <BoardList />

            ),
          },
          {
            path: 'write',
            element: (
              <WritePost />

            ),
          },
          {
            path: 'detail/:idx',
            element: (
              <DetailPost />

            ),
          },
        ],
      },
      {
        path: 'manage-career',
        children: [
          /** 회사 모곡 관리 */
          {
            path: 'companies',
            element: (
              <Companies />

            ),
          },
          /** 프로젝트 관리 */
          {
            path: 'project-list',
            element: (
              <ProjectList />

            ),
          },
          /** 소개 관리 */
          {
            path: 'introduce',
            element: (
              <Introduce />

            ),
          },
          /** 업무 관리  */
          {
            path: 'company-and-tasks',
            element: (
              <CompanyAndTasks />

            ),
          },
          {
            path: 'task-detail/:id',
            element: (
              <TaskDetail />

            ),
          },
        ],
      },
    ],
  }
]);

export default routerGroup;
