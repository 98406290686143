import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom';
import router from '@/routes';
import '@/assets/styles/index.scss';

const title: HTMLTitleElement | null = document.querySelector('title');

if (title && import.meta.env.MODE === 'development') {
  title.innerText = ':: 똥그라미 🧑🏻‍💻‍💻️::';
} else if (title && import.meta.env.MODE === 'production') {
  title.innerText = ':: 똥그라미 ::';
}

ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement)
  .render(
    <RouterProvider router={router} />
);
