import axios from 'axios';
import { useNavigate } from 'react-router-dom';

/**
 * axios 초기 설정
 */
const configAxios = axios.create({
  baseURL: import.meta.env.VITE_APP_REST_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

configAxios.interceptors.request.use(function (config) {
  if (!config.url?.includes('/admin/auth/')) {
    // 카카오 엑세스 토큰 검증
    axios.get(
      'https://kapi.kakao.com/v1/user/access_token_info',
      {
        headers: {
          Authorization: `Bearer ${ sessionStorage.getItem('kakaoAccessToken') }`
        }
      })
      .then(() => {
        console.log(
          `%cKakao Token Verification`,
          'color: black; background-color: #fee500; padding: 2px 5px;',
        );
      })
      .catch((error) => {
        console.log(
          `%cKakao token verification failed`,
          'color: black; background-color: Indianred; padding: 2px 5px;',
        );

        if (error.response.status === 401) {
          const navigate = useNavigate();
          sessionStorage.removeItem('kakaoAccessToken');
          sessionStorage.removeItem('kakaoRefreshToken');

          navigate('/login');

        }
      });
  }

  return config;
}, function (error) {
  // 요청 오류가 있는 작업 수행
  return Promise.reject(error);
});

// 응답 인터셉터 추가하기
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

export default configAxios;
