interface Props {
  title: string;
  text: string;
  confirm: () => void;
  confirmText: string;
}

const Modal = (props: Props) => {
  return (
    <>
      <div className="modal-mask" />
      <div className="modal">
        <div className="title">{props.title}</div>
        <div className="text">{props.text}</div>
        <div className="buttons">
          <button onClick={props.confirm}>{props.confirmText}</button>
        </div>
      </div>
    </>
  );
};

export default Modal;
