import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import _ from 'lodash-es';
import queryString from 'query-string';
import apiAdmin from '@/services/api/apis/api-admin';

const Menu = () => {
  const location = useLocation();
  const [tags, setTags] = useState([]);

  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());

  useEffect(() => {
    apiAdmin.getTagsInBoard().then((res) => {
      setTags(res.data.tags);
    });
  }, []);

  return (
    <nav>
      <div className="title">관리자</div>
      <div className="nav-menu">
        <ul className="menus">
          <li>
            <div className="main-category">
              <Link to="/" className={`${location.pathname === '/admin' ? 'active' : null}`}>
                메인
              </Link>
            </div>
          </li>
          {/*<li>*/}
          {/*  <div className="main-category">*/}
          {/*    <Link*/}
          {/*      to="/manage-category"*/}
          {/*      className={`${location.pathname.includes('manage-category') ? 'active' : null}`}*/}
          {/*    >*/}
          {/*      메뉴 관리*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</li>*/}
          <li>
            <div className="main-category">
              <Link
                to={`/manage-board/list?tag=${ tags[0] }`}
                className={`${location.pathname.includes('manage-board') ? 'active' : null}`}
              >
                게시물 관리
              </Link>
            </div>
            <div
              className={`sub-category ${location.pathname.includes('manage-board') ? 'show' : ''}`}
            >
              <ul>
                {location.pathname.includes('manage-board') &&
                  _.map(tags, (tag, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/manage-board/list?tag=${tag}`}
                          className={`${
                            query.tag === tag
                              ? 'active'
                              : ''
                          }`}
                        >
                          {tag}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </li>
          <li>
            <div className="main-category">
              <Link
                to="/manage-career/companies"
                className={`${location.pathname.includes('manage-career') ? 'active' : null}`}
              >
                경력 관리
              </Link>
            </div>
            <div
              className={`sub-category ${
                location.pathname.includes('manage-career') ? 'show' : ''
              }`}
            >
              <ul>
                <li>
                  <Link
                    to="/manage-career/companies"
                    className={`${location.pathname.includes('companies') ? 'active' : null}`}
                  >
                    회사 목록 관리
                  </Link>
                </li>

                <li>
                  <Link
                    to="/manage-career/introduce"
                    className={`${location.pathname.includes('introduce') ? 'active' : null}`}
                  >
                    소개 관리
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manage-career/company-and-tasks"
                    className={
                      `${
                        location.pathname.includes('company-and-tasks')|| location.pathname.includes('task-detail')
                          ? 'active'
                          : null
                      }`
                    }
                  >
                    업무 관리
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manage-career/project-list"
                    className={`${location.pathname.includes('project-list') ? 'active' : null}`}
                  >
                    프로젝트 관리
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div className={'nav-footer'}>
        <span className={'profile-image'}/>donggil.hyun
      </div>
    </nav>
  );
};

export default Menu;
